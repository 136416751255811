<template>
<div class='magic-link-page'>
  <transition name='fast-fade'>
    <div
      v-if='ready'
      class='magic-link-page_inner'
    >
      <h2 class='magic-link-page_info heading-four'>
        {{ $t('one_time_password_link_expired_or_used') }}
      </h2>

      <router-link
        :to="{ path: '/magic-link/send' }"
        class='magic-link-page_link copy-four'
      >
        {{ $t('magic_link.back_to') }}
      </router-link>
    </div>
  </transition>
</div>
</template>

<script>
export default {
  data() {
    return {
      ready: false,
    }
  },

  created() {
    this.ready = true
  }
}
</script>

<style lang='sass' scoped>
  @import './MagicLink'
</style>
