<template>
<div class='account-app application'>
  <PageHeader
    :hide_sign_up='true'
    :hide_log_in='true'
    :hide_links='true'
    :logo='header_logo'
    :subdomain='subdomain'
  />

  <router-view v-slot="{ Component }">
    <transition
      name='fast-fade'
      mode='out-in'
    >
      <component :is="Component" />
    </transition>
  </router-view>

  <!-- Cookies consent -->
  <CookiesConsent
    :enabled='show_cookie_notice'
    :subdomain='subdomain'
  />

  <!-- Custom Styles -->
  <CustomStyles />
</div>
</template>

<script>
import { inject } from 'vue'

import CookiesConsent from 'components/Shared/CookiesConsent/CookiesConsent'
import CustomStyles from 'components/Shared/CustomStyles/CustomStyles'
import PageHeader from '../../components/Header/PageHeader'
import DictionaryMixin from './dictionary-mixin'

export default {
  mixins: [DictionaryMixin],

  data() {
    return {
      options: null,
      configuration: null,
      subdomain: null,
      api_url: null,
      authentications: null,
      redirect_to_location: null,
      user: null,
      otp: null,
      header_logo: null,
      params: null,
      show_cookie_notice: null
    }
  },

  components: {
    CookiesConsent,
    CustomStyles,
    PageHeader
  },

  created() {
    this.options = inject('options')
    this.configuration = inject('configuration')

    this.subdomain = this.options.subdomain
    this.api_url = this.options.api_url
    this.authentications = this.options.authentications
    this.redirect_to_location = this.options.redirect_to_location
    this.user = this.options.user
    this.otp = this.options.otp
    this.header_logo = this.options.header_logo
    this.params = this.options.params
    this.show_cookie_notice = this.options.show_cookie_notice
  }
}
</script>

<style lang='sass' scoped>
  @import './app.sass'
</style>
