<template>
  <div class='sign-in-page'>
    <h1
      class='sign-in-page_title heading-four'
      :class="[!basic_auth ? 'sign-in-page_saml-title' : '']"
    >
      {{ $t('log_in_description') }}
    </h1>

    <div class='sign-in-page_content'>
      <div
        v-if='saml_auth'
        class='sign-in-page_column'
      >
        <SamlAuth
          :auth_data='saml_auth'
          :redirect_to_location='$root.redirect_to_location'
        />
      </div>

      <div
        v-if='oauth_auth'
        class='sign-in-page_column'
      >
        <OauthAuth
          :auth_data='oauth_auth'
          :redirect_to_location='$root.redirect_to_location'
        />
      </div>

      <div
        v-if='otp_auth'
        class='sign-in-page_column'
      >
        <OTPAuth />
      </div>

      <div
        v-if='basic_auth'
        class='sign-in-page_column'
      >
        <SignInForm
          :auth_data='basic_auth'
          :redirect_to_location='$root.redirect_to_location'
        />
      </div>
    </div>

    <template v-if='basic_auth'>
      <span class='sign-in-page_help-text copy-four'>{{ $t('first_time_here') }}</span>

      <router-link
        :to="{ path: '/sign-up'}"
        class='sign-in-page_link copy-four'
      >
        {{ $t('sign_up') }}
      </router-link>
    </template>
  </div>
</template>

<script>
import OauthAuth from './OauthAuth/OauthAuth'
import SignInForm from './SignInForm/SignInForm'
import SamlAuth from './SamlAuth/SamlAuth'
import OTPAuth from './OTPAuth/OTPAuth'

export default {
  components: {
    OTPAuth,
    OauthAuth,
    SignInForm,
    SamlAuth
  },

  data() {
    return {
      basic_auth: null,
      saml_auth: null,
      oauth_auth: null,
      otp_auth: null,
      show_sign_in: true,
      show_sign_up: false
    }
  },

  created() {
    this.$root.authentications.forEach((auth) => {
      if (auth.auth_type === 'basic') {
        this.basic_auth = auth
      }

      if (auth.auth_type === 'oauth') {
        this.oauth_auth = auth
      }

      if (auth.auth_type === 'saml') {
        this.saml_auth = auth
      }

      if (auth.auth_type === 'otp') {
        this.otp_auth = auth
      }
    })
  }
}

</script>

<style lang='sass' scoped>
  @import './SignInPage.sass'
</style>
