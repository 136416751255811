import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { setUpI18n } from 'tbk-utils/i18n'
import VueScrollTo from 'vue-scrollto'

import Account from './components/app.vue'
import SignInPage from './components/SignInPage/SignInPage'
import SignUpPage from './components/SignUpPage/SignUpPage'
import ForgotPass from './components/ForgotPass/ForgotPass'
import ResetPass from './components/ResetPass/ResetPass'
import SendMagicLink from './components/SignInPage/OTPAuth/SendMagicLink'
import FailedMagicLink from './components/SignInPage/OTPAuth/FailedMagicLink'

const routes = [
  { path: '/sign-in', component: SignInPage },
  { path: '/sign-up', component: SignUpPage },
  { path: '/forgot-password', component: ForgotPass },
  { path: '/reset-password', component: ResetPass },
  { path: '/magic-link/send', component: SendMagicLink },
  { path: '/magic-link/failed', component: FailedMagicLink }
]

const router = createRouter({
  history: createWebHistory('/account'),
  routes
})

/* **  App Init ** */
window.initAccount = function(options) {
  const i18n = setUpI18n(options.translations)
  if (options.translations) {
    delete options.translations
  }

  if (options.locale) {
    i18n.locale = options.locale
  }

  const app = createApp(Account)
  app.use(router)
  app.use(i18n)
  app.use(VueScrollTo)

  // provide options
  app.provide('options', options)
  app.provide('configuration', options.configuration)

  // finally mount the app
  app.mount('#account-app')
}
