<template>
<div class='magic-link-page'>
  <div
    v-if='!response_message'
    class='magic-link-page_inner'
  >
    <h2 class='magic-link-page_title heading-four'>
      {{ $t('magic_link.title') }}
    </h2>

    <p class='magic-link-page_description copy-two'>
      {{ $t('magic_link.description') }}
    </p>

    <MagicLinkForm
      :auth='auth'
    />

    <router-link
      v-if="$root.authentications.length > 1"
      :to="{ path: '/sign-in' }"
      class='magic-link-page_link copy-four'
    >
      {{ $t('back_to_log_in') }}
    </router-link>
  </div>

  <transition name='fast-fade'>
    <div
      v-if='response_message'
      class='magic-link-page_inner'
    >
      <h2 class='magic-link-page_info heading-four'>
        {{ response_message }}
      </h2>

      <router-link
        v-if="$root.authentications.length > 1"
        :to="{ path: '/sign-in' }"
        class='magic-link-page_link copy-four'
      >
        {{ $t('back_to_log_in') }}
      </router-link>
    </div>
  </transition>
</div>
</template>

<script>
import MagicLinkForm from './MagicLinkForm'

export default {
  props: [],

  components: {
    MagicLinkForm
  },

  data() {
    return {
      user_email: null,
      response_message: null
    }
  },

  created() {
    if (this.$root.params && this.$root.params.sent) {
      this.response_message = this.$t('magic_link.sent')
    }

    this.user_email = this.$root.user.email
  },

  computed: {
    auth() {
      return this.$root.authentications.filter((a) => a.auth_type === 'otp')[0]
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './MagicLink'
</style>
