<template>
<div class='reset-pass-page'>
  <div
    v-if='otp'
    class='reset-pass-page_inner'
  >
    <h2 class='reset-pass-page_title heading-four'>
      {{ $t('reset_your_password') }}
    </h2>

    <VeeForm
      v-slot="{ meta, errors }"
      class='reset-pass_form'
      @submit='submitForm'
    >
      <div class='reset-pass_field'>
        <label
          for='user_password'
          class='ada-hidden'
        >
          {{ $t('new_password') }}
        </label>

        <Field
          id='user_password'
          v-model='password'
          name='user_password'
          type='password'
          rules='required|min:8'
          :placeholder="$t('new_password')"
          :validateOnInput="true"
        />

        <span
          v-if='errors.user_password'
          class='form-error'
        >
          Password must be at least 8 characters
        </span>
      </div>

      <div class='reset-pass_field'>
        <input
          type='submit'
          :value="$t('reset_password')"
          class='reset-pass_submit-button action-one-branded'
          :disabled='!meta.valid'
          @click.prevent='submitForm'
        >
      </div>
    </VeeForm>

    <router-link
      :to="{ path: '/sign-in' }"
      class='reset-pass-page_link'
    >
      {{ $t('back_to_log_in') }}
    </router-link>
  </div>

  <transition name='fast-fade'>
    <div
      v-if='error_message'
      class='reset-pass-page_inner'
    >
      <h2 class='reset-pass-page_info'>
        {{ error_message }}
      </h2>

      <router-link
        :to="{ path: '/forgot-password' }"
        class='reset-pass-page_link'
      >
        {{ $t('back_to_reset_password') }}
      </router-link>
    </div>
  </transition>
</div>
</template>

<script>
import { Form as VeeForm, Field } from 'vee-validate'
import { defineRule } from 'vee-validate'
import { required, min } from '@vee-validate/rules'
import { resetPassword } from '../../api'


export default {
  components: {
    VeeForm,
    Field
  },

  data() {
    return {
      password: null,
      basic_auth: null,
      otp: null,
      error_message: null
    }
  },

  created() {
    // validation rules
    defineRule('required', required);
    defineRule('min', min);

    this.otp = this.$root.otp
    this.basic_auth = this.$root.authentications.filter((a) => a.auth_type === 'basic')[0]
    if (!this.otp) {
      this.error_message = this.$t('one_time_password_link_expired_or_used')
    }
  },

  methods: {
    submitForm() {
      const data = {
        path: this.basic_auth.reset_password_path,
        password: this.password,
        password_confirmation: this.password,
        otp: this.otp
      }

      resetPassword(data, (response) => {
        if (response.data.success) {
          window.location = window.location.origin
        } else {
          this.otp = null
          if (response.data.message) {
            this.error_message = response.data.message
          }
        }
      })
    }
  }
}

</script>

<style lang='sass' scoped>
  @import './ResetPass'
</style>
