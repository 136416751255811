<template>
<div class='forgot-pass-page'>
  <div
    v-if='!response_message'
    class='forgot-pass-page_inner'
  >
    <h2 class='forgot-pass-page_title heading-four'>
      {{ $t('reset_your_password') }}
    </h2>

    <p class='forgot-pass-page_description copy-two'>
      {{ $t('reset_pass_description') }}
    </p>

    <ForgotPassForm
      :basic_auth='basic_auth'
    />

    <router-link
      :to="{ path: '/sign-in' }"
      class='forgot-pass-page_link copy-four'
    >
      {{ $t('back_to_log_in') }}
    </router-link>
  </div>

  <transition name='fast-fade'>
    <div
      v-if='response_message'
      class='forgot-pass-page_inner'
    >
      <h2 class='forgot-pass-page_info heading-four'>
        {{ response_message }}
      </h2>

      <router-link
        :to="{ path: '/sign-in' }"
        class='forgot-pass-page_link copy-four'
      >
        {{ $t('back_to_log_in') }}
      </router-link>
    </div>
  </transition>
</div>
</template>

<script>
import ForgotPassForm from './ForgotPassForm'

export default {
  props: [],

  components: {
    ForgotPassForm
  },

  data() {
    return {
      response_message: null
    }
  },

  computed: {
    basic_auth() {
      return this.$root.authentications.filter((a) => a.auth_type === 'basic')[0]
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './ForgotPass'
</style>
