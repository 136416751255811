<template>
<div class='sign-up-page'>
  <h1 class='sign-up-page_title heading-four'>
    {{ $t('sign_up') }}
  </h1>

  <SignUpForm
    :user='$root.user'
    :api_url='$root.api_url'
    :subdomain='$root.subdomain'
    :redirect_to_location='$root.redirect_to_location'
  />

  <div v-if="oauth_auth">
    <OauthAuth
      :auth_data='oauth_auth'
      :redirect_to_location='$root.redirect_to_location'
      :sign_up=true
    />
  </div>

  <span class='sign-up-page_help-text copy-three'>{{ $t('already_have_account') }}</span>

  <router-link
    :to="{ path: '/sign-in'}"
    class='sign-up-page_link copy-three'
  >
    {{ $t('log_in') }}
  </router-link>
</div>
</template>

<script>
import OauthAuth from '../SignInPage/OauthAuth/OauthAuth'
import SignUpForm from './SignUpForm'

export default {
  components: {
    OauthAuth,
    SignUpForm
  },

  computed: {
    oauth_auth() {
      return this.$root.authentications.find(e => e.auth_type === 'oauth')
    }
  }
}

</script>

<style lang='sass' scoped>
  @import './SignUpPage.sass'
</style>
